.navbar {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  z-index: 999;
  backdrop-filter: blur(10px);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  background: rgba(255, 255, 255, 0.011);
  box-sizing: border-box;
}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.sticky {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.sticky .logo img {
  transform: scale(0.95);
}

.logo img {
  height: 40px;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.menuItems {
  display: flex;
  gap: 40px;
  direction: rtl;
}

.menuItems a {
  color: #000;
  text-decoration: none;
  font-family: "AssistantR";
  font-size: 1.1rem;
  letter-spacing: 0.04em;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  padding: 4px 0;
  font-weight: 400;
}

.menuItems a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #1a1a1a;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.menuItems a:hover {
  color: #000;
}

.menuItems a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.socialIcons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
}



.iconContainer:hover::before {
  transform: scale(1);
}

.iconContainer:hover {
  color: #000;
  transform: translateY(-1px);
}

.icon {
  width: 20px;
  height: 20px;
  position: relative;
  text-decoration: none;
  color: black;
  border: none;
  outline: none;
  background: none;
  z-index: 1;
}

.hamburger {

  position: relative;
  text-decoration: none;
  color: black;
  border: none;
  outline: none;
  background: none;
  z-index: 1501;
}



.hamburger:hover::before {
  transform: scale(1);
}

.mobileMenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  backdrop-filter: blur(20px);
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  transform: perspective(1000px) rotateX(-10deg);
  transform-origin: top;
  background: rgba(255, 255, 255, 0.95);
}

.mobileMenu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.8s ease;
}

.mobileMenuOpen {
  opacity: 1;
  visibility: visible;
  transform: perspective(1000px) rotateX(0);
}

.mobileMenuOpen::before {
  opacity: 1;
}

.mobileMenuContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

.mobileMenuOpen .mobileMenuContent {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.3s;
}

.mobileMenuItem {
  color: #000;
  text-decoration: none;
  font-size: 1.3rem;
  font-family: "AssistantR";
  cursor: pointer;
  transition: all 0.3s ease;
  letter-spacing: 0.02em;
  padding: 8px 0;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: calc(var(--item-index) * 0.1s);
}

.mobileMenuOpen .mobileMenuItem {
  opacity: 1;
  transform: translateY(0);
}

.mobileMenuItem::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 4px;
  left: 0;
  background-color: #1a1a1a;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.mobileMenuItem:hover {
  color: #000;
}

.mobileMenuItem:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.center {
  display: flex;
  justify-content: center;
  margin: 40px auto 0;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 0.5s;
}

.mobileMenuOpen .center {
  opacity: 1;
  transform: scale(1);
}

.image {
  width: 80px;
  height: auto;
  object-fit: contain;
  opacity: 0.9;
}

/* Responsive Design */
@media (min-width: 1150px) and (max-width: 1250px) {
  .menuItems {
    gap: 35px;
  }
  .menuItems a {
    font-size: 1rem;
  }
}

@media (min-width: 1050px) and (max-width: 1150px) {
  .menuItems {
    gap: 30px;
  }
  .menuItems a {
    font-size: 0.95rem;
  }
  .navbar {
    padding: 0 35px;
  }
}

@media (min-width: 950px) and (max-width: 1050px) {
  .menuItems {
    gap: 25px;
  }
  .menuItems a {
    font-size: 0.9rem;
  }
  .navbar {
    padding: 0 30px;
  }
}

@media (min-width: 850px) and (max-width: 950px) {
  .menuItems {
    gap: 20px;
  }
  .menuItems a {
    font-size: 0.85rem;
  }
  .navbar {
    padding: 0 25px;
  }
}

@media (min-width: 750px) and (max-width: 850px) {
  .navbar {
    padding: 0 25px;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
  .iconContainer {
    width: 45px;
    height: 45px;
  }
  .hamburger .icon {
    width: 35px;
    height: 35px;
  }
  .logo img {
    height: 35px;
  }
}

@media (min-width: 650px) and (max-width: 750px) {
  .navbar {
    padding: 0 22px;
    height: 75px;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
  .iconContainer {
    width: 45px;
    height: 45px;
  }
  .hamburger .icon {
    width: 35px;
    height: 35px;
  }
  .logo img {
    height: 35px;
  }
  .mobileMenuItem {
    font-size: 1.25rem;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .navbar {
    padding: 0 20px;
    height: 75px;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
  .iconContainer {
    width: 45px;
    height: 45px;
  }
  .hamburger .icon {
    width: 35px;
    height: 35px;
  }
  .logo img {
    height: 35px;
  }
  .mobileMenuItem {
    font-size: 1.2rem;
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .navbar {
    padding: 0 18px;
    height: 70px;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
  .iconContainer {
    width: 45px;
    height: 45px;
  }
  .hamburger .icon {
    width: 35px;
    height: 35px;
  }
  .logo img {
    height: 32px;
  }
  .mobileMenuItem {
    font-size: 1.15rem;
  }
  .socialIcons {
    gap: 15px;
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .navbar {
    padding: 0 15px;
    height: 70px;
  }
  .icon {
    width: 25px;
    height: 25px;
  }
  .iconContainer {
    width: 35px;
    height: 35px;
  }
  .hamburger {
  
    width: 50px;
    height: 50px;
  }
  .logo img {
    height: 30px;
  }
  .mobileMenuItem {
    font-size: 1.1rem;
  }
  .socialIcons {
    gap: 12px;
  }
}

@media (max-width: 350px) {
  .navbar {
    padding: 0 12px;
    height: 65px;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
  .iconContainer {
    width: 25px;
    height: 25px;
  }
  .hamburger{
    width: 35px;
    height: 35px;
  }
  .logo img {
    height: 28px;
  }
  .mobileMenuItem {
    font-size: 1rem;
  }
  .socialIcons {
    gap: 10px;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .sticky,
  .mobileMenu {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

.active {
  color: #000 !important;
}

.active::after {
  transform: scaleX(1) !important;
}