.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto 25px;
  perspective: 1000px;
}

.button {
  font-family: 'Assistant', sans-serif;
  position: relative;
  padding: 0.95rem 2.6rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(31, 41, 55);
  background: rgba(255, 255, 255, 0.97);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  direction: rtl;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  box-shadow: 
    0 4px 15px -3px rgba(0, 0, 0, 0.1),
    0 2px 8px -2px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(212, 175, 55, 0.08);
}

.button::before,
.button::after {
  content: '';
  position: absolute;
  inset: 0;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
}

.button::before {
  background: radial-gradient(
    800px circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
    rgba(212, 175, 55, 0.06),
    transparent 40%
  );
  opacity: 0;
  z-index: 1;
}

.button::after {
  background: radial-gradient(
    600px circle at var(--mouse-x, 50%) var(--mouse-y, 50%),
    rgba(255, 255, 255, 0.4),
    transparent 40%
  );
  opacity: 0;
  z-index: 2;
}

.buttonContent {
  position: relative;
  z-index: 3;
}

.button:hover {
  transform: translateY(-1px) scale(1.01);
;

}



.button:active {
  transform: translateY(1px) scale(0.99);
  box-shadow: 
    0 5px 15px -4px rgba(0, 0, 0, 0.2),
    0 2px 6px -2px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(212, 175, 55, 0.15),
    0 0 15px rgba(212, 175, 55, 0.08);
}

@media (max-width: 768px) {
  .button {
    padding: 0.85rem 2.2rem;
    font-size: 1rem;
  }
}