.container {
  display: flex;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.row {
  display: flex;
  justify-content: space-evenly;
  margin: 2% auto;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 20px;
  padding: 0 20px;
}

.header {
  font-size: 3rem;
  font-family: "Assistant";
  text-align: center;
  margin: 0 auto 2%;
  padding-top: 2%;
  direction: rtl;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.imageContainer {
  flex: 1;
  padding-right: 20px;
  position: relative;
}

.image {
  width: 90%;
  display: flex;
  height: auto;
  max-height: 500px;
  border-radius: 20px;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 2;
}

.image2 {
  width: 300px;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  cursor: pointer;
  filter: brightness(0.95);
}

.image2:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  filter: brightness(1.05);
}

.imageContainer:hover .image {
  transform: scale(1.04) rotate(1.5deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.18);
}

.bottomImageContainer {
  width: 25%;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  transition: width 0.3s ease;
}

.bottomImage {
  width: 100%;
  height: auto;
  max-height: 500px;
  border-radius: 20px;
  filter: drop-shadow(2px 4px 8px rgb(178, 178, 178));
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.content {
  flex: 2;
  text-align: right;
  direction: rtl;
}

.title {
  font-size: 2.5rem;
  font-family: "Assistant";
  text-align: right;
  direction: rtl;
  width: 90%;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
}

.subtitle {
  font-size: 1.4rem;
  font-family: "Assistant";
  text-align: right;
  margin: 1% auto;
  direction: rtl;
  font-weight: 800;
  line-height: 1.4;
}

.subheader {
  font-size: 2rem;
  font-family: "Assistant";
  text-align: center;
  margin: 0 auto 2%;
  padding-top: 2%;
  direction: rtl;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
}

.description {
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 300;
  font-family: "AssistantR";
  margin-bottom: 15px;
}

@media only screen and (max-width: 1050px) {
  .container {
    width: 90%;
    max-width: none;
  }
  .image {
    justify-content: center;
  }
  .bottomImageContainer {
    width: 30%;
  }
  .image2 {
    width: 250px;
    height: 330px;
    margin: 25px;
  }
  .row {
    max-width: 90%;
    gap: 15px;
  }
  .subheader {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 350px) {
  .container {
    flex-direction: column;
    padding: 10px;
  }
  .image {
    margin: 2% auto;
  }
  .header {
    font-size: 2.2rem;
  }
  .imageContainer {
    padding-right: 0;
    padding-bottom: 10px;
  }
  .content {
    text-align: center;
  }
  .title, .subtitle {
    text-align: center;
    margin: 2% auto;
    width: 100%;
  }
  .description {
    font-size: 1rem;
  }
  .bottomImageContainer {
    width: 85%;
  }
  .image2 {
    width: 85%;
    height: 260px;
    margin: 20px auto;
  }
  .row {
    padding: 0 10px;
    gap: 10px;
  }
  .subheader {
    font-size: 1.5rem;
    padding-top: 1%;
  }
  .bottomImage {
    max-height: 400px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .container {
    flex-direction: column;
    padding: 15px;
  }
  .image {
    margin: 2% auto;
  }
  .header {
    font-size: 2.4rem;
  }
  .imageContainer {
    padding-right: 0;
    padding-bottom: 15px;
    width: 85%;
    margin: 0 auto;
  }
  .content {
    text-align: center;
  }
  .title, .subtitle {
    text-align: center;
    margin: 2% auto;
    width: 100%;
  }
  .bottomImageContainer {
    width: 60%;
  }
  .image2 {
    width: 90%;
    height: 280px;
    margin: 22px auto;
  }
  .row {
    padding: 0 15px;
  }
  .subheader {
    font-size: 1.6rem;
  }
  .description {
    font-size: 1.05rem;
  }
  .bottomImage {
    max-height: 420px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  .container {
    flex-direction: column;
  }
  .header {
    font-size: 3rem;
  }
  .image {
    margin: 2% auto;
  }
  .imageContainer {
    width: 60%;
    margin: 0 auto;
    padding-right: 0;
    padding-bottom: 20px;
  }
  .content {
    text-align: right;
  }
  .title, .subtitle {
    text-align: center;
    margin: 2% auto;
    width: 100%;
  }
  .bottomImageContainer {
    width: 45%;
  }
  .image2 {
    width: 90%;
    height: 300px;
    margin: 25px auto;
  }
  .row {
    padding: 0 15px;
  }
  .subheader {
    font-size: 1.7rem;
  }
  .description {
    font-size: 1.08rem;
  }
  .bottomImage {
    max-height: 450px;
  }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
  .header {
    font-size: 3.1rem;
  }
  .image {
    margin: 2% auto;
  }
  .title {
    font-size: 2.3em;
  }
  .bottomImageContainer {
    width: 40%;
  }
  .row {
    max-width: 95%;
  }
  .subheader {
    font-size: 1.8rem;
  }
  .subtitle {
    font-size: 1.3rem;
  }
  .description {
    font-size: 1.09rem;
  }
  .bottomImage {
    max-height: 470px;
  }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
  .header {
    font-size: 3.2rem;
  }
  .title {
    font-size: 2.4em;
  }
  .bottomImageContainer {
    width: 35%;
  }
  .row {
    max-width: 95%;
  }
  .subheader {
    font-size: 1.9rem;
  }
  .subtitle {
    font-size: 1.35rem;
  }
  .bottomImage {
    max-height: 480px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
  .header {
    font-size: 3.3rem;
  }
  .bottomImageContainer {
    width: 30%;
  }
  .row {
    max-width: 95%;
  }
  .subheader {
    font-size: 1.95rem;
  }
  .bottomImage {
    max-height: 490px;
  }
}

@media only screen and (min-width: 850px) and (max-width: 950px) {
  .header {
    font-size: 3.4rem;
  }
  .bottomImageContainer {
    width: 28%;
  }
  .row {
    max-width: 95%;
  }
  .subheader {
    font-size: 1.97rem;
  }
  .bottomImage {
    max-height: 495px;
  }
}

@media only screen and (min-width: 950px) and (max-width: 1050px) {
  .header {
    font-size: 3.5rem;
  }
  .bottomImageContainer {
    width: 26%;
  }
  .row {
    max-width: 95%;
  }
  .subheader {
    font-size: 2rem;
  }
  .bottomImage {
    max-height: 500px;
  }
}