.container {
    max-width: 42rem;
    margin: 1.25rem auto;
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.95));
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 1rem;
    box-shadow: 
      0 10px 30px -5px rgba(0, 0, 0, 0.08),
      0 20px 60px -10px rgba(0, 0, 0, 0.05),
      0 0 120px rgba(0, 0, 0, 0.02);
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.2);
    direction: rtl;
  }
  
  .toggleButton {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .toggleButton:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .buttonContent {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .courseImage {
    width: 5.5rem;
    height: 5.5rem;
    object-fit: cover;
    border-radius: 0.75rem;

  }
  
  .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
  }
  
  .title {
    font-family: 'Assistant', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin:0 auto;
    color: #000;
    letter-spacing: -0.02em;
  }
  
  .subtitle {
    font-family: 'AssistantR', sans-serif;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1rem;
  }
  
  .toggleIcon {
    color: #C5A572;
    font-size: 1.75rem;
    font-weight: 300;
  }
  
  .contentWrapper {
    transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
  }
  
  .content {
    padding: 2rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8));
    border-top: 1px solid rgba(197, 165, 114, 0.1);
  }
  
  .courseItemsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .courseItem {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: 'AssistantR', sans-serif;
    color: rgba(0, 0, 0, 0.8);
  }
  
  .icon {
    color: #C5A572;
    width: 1.375rem;
    height: 1.375rem;
  }
  
  .benefitsSection {
    margin-bottom: 2rem;
  }
  
  .benefitsTitle {
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
    color: #000;
    margin-bottom: 1.25rem;
    font-size: 1.125rem;
  }
  
  .benefitsList {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .benefitItem {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: 'AssistantR', sans-serif;
    color: rgba(0, 0, 0, 0.8);
  }
  
  .bullet {
    color: #C5A572;
    font-size: 1.25rem;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .duration {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: 'Assistant', sans-serif;
    font-weight: 600;
    color: #000;
  }
  
  @media (max-width: 768px) {
    .container {
      margin: 1rem;
    }
    
    .courseImage {
      width: 3.5rem;
      height: 3.5rem;
    }
    
    .title {
      font-size: 1.125rem;
    }
    
    .content {
      padding: 1.5rem;
    }
    
    .courseItemsGrid {
      grid-template-columns: 1fr;
    }
  }