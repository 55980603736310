.container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .explain {
    font-size: 1.4rem;
    direction: rtl;
    width: 70%;
    text-align: center;
    margin: 2% auto;
    font-family: "AssistantR";
  }
  
  .title {
    font-size: 2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin: 1% auto;
    direction: rtl;
    width: 100%;
    font-weight: 800;
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .scrollTrack {
    display: flex;
    width: fit-content;
    animation: scroll 30s linear infinite;
    gap: 20px;
  }
  
  .scrollContainer {
    display: flex;
    gap: 20px;
    padding: 20px 0;
  }
  
  .imageWrapper {
    flex-shrink: 0;
    width: 300px;
    height: 400px;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Under 350px */
  @media only screen and (max-width: 349px) {
    .title {
      font-size: 1.6rem;
    }
    .explain {
      font-size: 1.1rem;
      width: 85%;
    }
    .imageWrapper {
      width: 200px;
      height: 300px;
    }
  }
  
  /* 350px - 450px */
  @media only screen and (min-width: 350px) and (max-width: 450px) {
    .title {
      font-size: 1.6rem;
    }
    .explain {
      font-size: 1.2rem;
      width: 90%;
    }
    .imageWrapper {
      width: 230px;
      height: 330px;
    }
  }
  
  /* 450px - 550px */
  @media only screen and (min-width: 451px) and (max-width: 550px) {
    .title {
      font-size: 2rem;
    }
    .explain {
      font-size: 1.3rem;
      width: 85%;
    }
    .imageWrapper {
      width: 250px;
      height: 350px;
    }
  }
  
  /* 550px - 650px */
  @media only screen and (min-width: 551px) and (max-width: 650px) {
    .title {
      font-size: 2.1rem;
    }
    .imageWrapper {
      width: 260px;
      height: 360px;
    }
  }
  
  /* 650px - 750px */
  @media only screen and (min-width: 651px) and (max-width: 750px) {
    .title {
      font-size: 2.2rem;
    }
    .imageWrapper {
      width: 270px;
      height: 370px;
    }
  }
  
  /* 750px - 850px */
  @media only screen and (min-width: 751px) and (max-width: 850px) {
    .title {
      font-size: 2.3rem;
    }
    .imageWrapper {
      width: 280px;
      height: 380px;
    }
  }
  
  /* 850px - 950px */
  @media only screen and (min-width: 851px) and (max-width: 950px) {
    .title {
      font-size: 2.4rem;
    }
    .imageWrapper {
      width: 285px;
      height: 385px;
    }
  }
  
  /* 950px - 1050px */
  @media only screen and (min-width: 951px) and (max-width: 1050px) {
    .title {
      font-size: 2.4rem;
    }
    .imageWrapper {
      width: 290px;
      height: 390px;
    }
  }
  
  /* 1050px - 1150px */
  @media only screen and (min-width: 1051px) and (max-width: 1150px) {
    .title {
      font-size: 2.5rem;
    }
    .imageWrapper {
      width: 295px;
      height: 395px;
    }
  }
  
  /* 1150px - 1250px */
  @media only screen and (min-width: 1151px) and (max-width: 1250px) {
    .title {
      font-size: 2.5rem;
    }
    .imageWrapper {
      width: 300px;
      height: 400px;
    }
  }